import { CardContact, Container, Content, SocialMedia } from "./styles";
import { RiComputerLine } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import { FaWhatsapp } from "react-icons/fa";

export function Contact() {
    return (
        <Container>
            <Content>
                <img src="arrowright.gif" alt="Seta" />

                <CardContact>
                    <h2>CONTATO</h2>
                    <p>Entre em contato para esclarecer dúvidas, compartilhar sugestões, conhecer meus serviços ou trocar ideias.</p>

                    <SocialMedia>

                        {/* <a href="https://lauralarocca.moonlab.com.br/" target="_blank" rel="noreferrer" title="Site/Portfólio">
                            <RiComputerLine />
                        </a> */}

                        <a href="mailto:lauralf.eso@gmail.com" target="_blank" rel="noreferrer" title="E-mail">
                            <GoMail />
                        </a>

                        <a href="https://wa.me/5547992256086" target="_blank" rel="noreferrer" title="WhatsApp">
                            <FaWhatsapp />
                        </a>

                    </SocialMedia>
                </CardContact>

                <img src="arrowleft.gif" alt="Seta" />
            </Content>


        </Container>
    )
}